import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import 'slick-carousel/slick/slick.scss';
import '../theme/slick-theme.scss';
// import request from '../utils/request';
// import bannersJson from '../fixtures/banners';

// import LeonisBackgroundImage from "./slides/leonisBackgroundImage"
import Banner from '@images/slides/banner.jpg';

const CarouselImage = styled.img`
  margin: 0 auto;
  border-radius: 12px;
  width: 100%;
`;

// const ROUTER_URL = '/api/v1.0/banners_home?requestType=json';

const Carousel = () => {

  // const [banners, setBanners] = useState([]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    appendDots: (dots) => (
      <Container>
        <ul className="p-0">{dots}</ul>
      </Container>
    ),
  };

  /* useEffect( () => {
    requestBanners().then((response) => {
      const { success, data, message } = response;
      if (success && data?.banners) {
        setBanners(data.banners);
      }
    });
  }, []);

  const requestBanners = async () => {
    const result = process.env.NODE_ENV === 'development' ?
      await (new Promise((resolve, reject) => resolve(bannersJson))) :
      await request(ROUTER_URL);
    return result;
  }; */

  const banners = [
    {
      url: Banner,
    },
    /* {
      url: "https://islastatics.s3.us-west-2.amazonaws.com/app/20200721/en/call.png",
    },
    {
      url: "https://islastatics.s3.us-west-2.amazonaws.com/app/20200721/en/nauta.png",
    },
    {
      url: "https://islastatics.s3.us-west-2.amazonaws.com/app/20200721/en/sms.png",
    }, */
  ];

  return (
    <div className="mt-3 pb-2 pb-md-4">
      <Slider {...settings}>
        {banners.map((item, index) => (
          <div key={index}>
            <CarouselImage src={item.url} alt="banner" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
