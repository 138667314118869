import React, { useState } from 'react';
import { graphql } from 'gatsby';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { Container, Modal, Button } from 'react-bootstrap';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import Carousel from '@components/carousel';
import AppStore from '@images/appstore.svg';
import GooglePlay from '@images/google-play.svg';
import AnnounceImage from '@images/app-announce.png';
import SeparatorSrc from '@images/separator-1.png';
import ContactMapBg from '@images/contact-map.jpg';
import ContactMapMobileBg from '@images/contact-map-sm.jpg';
import MapMarkerSrc from '@images/map-marker.png';

const StoreButton = styled.svg`
  cursor: pointer;
  @media (max-width: 767px) {
    width: 120px;
  }
`;

const Separator = styled.img`
  width: 360px;
`;

const ContactMapContainer = styled.div`
  height: 316px;
  width: 100%;
  position: relative;
  background-image: url(${ContactMapBg});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center center;
  border-radius: 20px;
  @media (max-width: 767px) {
    background-image: url(${ContactMapMobileBg});
    background-size: 100%;
  }
`;

const MapMarker = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100px;
`;

const IndexPage = () => {
  // const { t } = useTranslation();
  const [showComingSoonModal, setShowComingSoonModal] = useState(false);

  return (
    <>
      <Layout
        headerProps={{
          disableLogin: true,
          landingOnly: true,
        }}
      >
        <SEO title="Pizzería Habana"/>

        <Container>
          <Carousel/>
        </Container>

        <Container className="pb-4 pb-md-5">
          <div className="text-center">
            <div className="announce">
              <img src={AnnounceImage} alt="Mobile app banner" />
            </div>
            <h1 className="mb-3 mb-md-4">Pizzería Habana app</h1>
            <p className="lead mx-auto">
            Muy pronto disponible nuestra aplicación para la comodidad de nuestros
            usuarios <br className="d-none d-md-block" />
            con una interfaz simple, rápida y segura.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <StoreButton
                as={AppStore}
                className="me-3"
                onClick={() => setShowComingSoonModal(true)}
              />
              <StoreButton
                as={GooglePlay}
                onClick={() => setShowComingSoonModal(true)}
              />
            </div>
          </div>

          <div className="text-center my-5">
            <Separator src={SeparatorSrc}/>
          </div>
          
          <div className="text-center">
            <h1 className="mb-3 mb-md-4">Contáctenos</h1>
            <p>
              9280 SW 40th St, Miami, FL 33165
            </p>
            <p className="lead mx-auto">
              <a href="tel:305-859-4472">305-859-4472</a>
            </p>
            <ContactMapContainer>
              <MapMarker src={MapMarkerSrc}/>
            </ContactMapContainer>
          </div>
        </Container>
      </Layout>

      <Modal show={showComingSoonModal} centered>
        <Modal.Body className="text-center p-6">
          <h1>¡Muy pronto!</h1>
          <p className="my-4">
            Mantente al tanto para descargar la aplicación desde las tiendas...
          </p>
          <Button onClick={() => setShowComingSoonModal(false)}>Aceptar</Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
